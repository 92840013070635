import { NgPipesModule, ShufflePipe } from 'ngx-pipes';
import { first, map, Observable, zip } from 'rxjs';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AsyncPipe, CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
  HorizontalScrollComponent
} from '@app/components/horizontal-scroll/horizontal-scroll.component';
import { MiniBox, MiniBoxComponent } from '@app/components/mini-box/mini-box.component';
import { MiniDailyComponent } from '@app/components/mini-daily/mini-daily.component';
import { MiniTextComponent } from '@app/components/mini-text/mini-text.component';
import {
  PersonMiniCardComponent
} from '@app/components/person-mini-card/person-mini-card.component';
import { SerbianMapComponent } from '@app/components/serbian-map/serbian-map.component';
import { StatsCounterComponent } from '@app/components/stats-counter/stats-counter.component';
import { BasicSheetComponent } from '@app/dialogs/basic-sheet/basic-sheet.component';
import { MiniBoxParserPipe } from '@app/pipes/mini-box-parser.pipe';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { TrimWordPipe } from '@app/pipes/trim-word.pipe';
import { CategoriesService, Category } from '@app/services/categories.service';
import {
  Chronology, chronologyBoxes, ChronologyService, ChronologyType
} from '@app/services/chronology.service';
import { heritageBoxes } from '@app/services/church.service';
import { HistoricalEvent, HistoricalEventsService } from '@app/services/historical-events.service';
import { instragramTypes } from '@app/services/instagram.service';
import { Quote, QuotesService } from '@app/services/quotes.service';
import { Tag, TagsService } from '@app/services/tags.service';
import { DailyQuizComponent } from '@components/daily-quiz/daily-quiz.component';
import { Person, PersonsService } from '@services/persons.service';
import { SaintsService } from '@services/saints.service';
import { ThemeService } from '@services/theme.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-welcome-page',
  imports: [
    AsyncPipe,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    NgPipesModule,
    MatIconModule,
    PersonMiniCardComponent,
    MiniBoxComponent,
    MiniTextComponent,
    MiniDailyComponent,
    DailyQuizComponent,
    SerbianMapComponent,
    HorizontalScrollComponent,
    StatsCounterComponent,
    MiniBoxParserPipe,
    TranslitPipe,
    TrimWordPipe,
  ],
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  providers: [ShufflePipe, MatBottomSheet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomePageComponent {
  private personsService = inject(PersonsService);
  private saintsService = inject(SaintsService);
  private chronologyService = inject(ChronologyService);
  private tagsService = inject(TagsService);
  private categoriesService = inject(CategoriesService);
  private historicalEventsService = inject(HistoricalEventsService);
  private quotesService = inject(QuotesService);
  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private location = inject(Location);
  private matBottomSheet = inject(MatBottomSheet);

  private router = inject(Router);
  private breakpointObserver = inject(BreakpointObserver);
  private shuffle = inject(ShufflePipe);

  public themeService = inject(ThemeService);
  public tiles$!: Observable<Person[]>;
  public categories$!: Observable<Category[]>;
  public currentDate!: string;
  public dailyQuotes: Quote[] = [];
  public randomEvent!: any;
  public randomQuote!: Quote;
  public randomBirthday!: Person;
  public dailyEvents: any[] = [];
  public timelineEvents: Chronology[] = [];
  public heritageBoxes = heritageBoxes;
  public tagBoxes: MiniBox[] = [];
  public funfactBoxes: MiniBox[] = [];
  public chronologyBoxes = chronologyBoxes;
  public cards$!: Observable<Person[]>;
  public cards: Person[] = [];
  public gamePoints!: any[];
  public totalScore!: number;
  public smallScreen = true;
  public biggerMap = false;
  public eventsSubtitle = 'Догађаји и празници';
  public eventsTitle = 'На данашњи дан';
  public showItem: boolean[] = [];
  public slots: any[] = [];
  public currentYear = new Date().getFullYear();
  public instragramTypes: any[] = instragramTypes;
  public surnameList: any[] = [];
  public dashCards: any[] = [
    { id: 1, title: 'Рођендан', content: 'Tekst' },
    { id: 2, title: 'Цитат дана', content: 'Tekst' },
    { id: 3, title: 'На данашњи дан', content: 'Tekst' },
    { id: 4, title: 'Знаменитости', content: 'Tekst' },
    { id: 5, title: 'Статистика', content: 'Tekst' },
    { id: 6, title: 'Времеплов', content: 'Tekst' },
  ];

  public get imagePrefix(): string {
    return this.themeService.isDarkMode()
      ? `radial-gradient(circle, rgb(0 0 0 / 85%) 0%, black 95%), linear-gradient(#353535, #222222), `
      : `radial-gradient(circle, rgb(255 255 255 / 80%) 0%, white 95%), linear-gradient(#c8c8c8, #c8c8c8), `;
  };

  ngOnInit(): void {
    UtilsService.scrollTop();
    this.listenBreakpoints();
    this.getPersonCards();
    this.getBirthdayPersons();
    this.getDailyHolidays();
    this.getQuotes();
    this.getDailyEvents();
    this.checkDailyQuiz();
    this.getTimelineEvents();
    this.getTiles();
    this.getCategories();
    this.getTags();
    this.getFunFacts();
    this.loadSlots();
    this.getSurnames();
  }

  loadSlots() {
    const slotStorage = localStorage.getItem('slots');
    const dashIndexes = slotStorage
      ? JSON.parse(slotStorage)
      : [1, 3, 2, 4, 5, 6];

    const slot1 = this.getDashCardById(dashIndexes[0]);
    const slot2 = this.getDashCardById(dashIndexes[1]);
    const slot3 = this.getDashCardById(dashIndexes[2]);
    const slot4 = this.getDashCardById(dashIndexes[3]);
    const slot5 = this.getDashCardById(dashIndexes[4]);
    const slot6 = this.getDashCardById(dashIndexes[5]);

    this.slots = [slot1, slot2, slot3, slot4, slot5, slot6];
  }

  getDashCardById(id: number) {
    return this.dashCards.find(c => c.id === id);
  }

  getTags() {
    this.tagsService.getItems()
      .pipe(
        map(response => {
          return response.filter(t => t.trending);
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: Tag[]) => {
          UtilsService.shuffleListOrder(response);

          this.tagBoxes = response
            .map((tag: Tag) => {
              return {
                style: '',
                imagePath: tag.imagePath || '',
                title: tag.name || '',
                link: tag.slug || '',
                icon: null,
                background: '',
              };
            });

        }
      });

  }

  listenBreakpoints() {
    this.biggerMap = this.breakpointObserver.isMatched([
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]);
    this.smallScreen = this.breakpointObserver.isMatched([
      Breakpoints.Small,
      Breakpoints.HandsetPortrait
    ]);

    this.breakpointObserver.observe([
      Breakpoints.Web,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result.matches) {
          this.smallScreen = result.breakpoints[Breakpoints.Small]
            || result.breakpoints[Breakpoints.HandsetPortrait]
            || result.breakpoints[Breakpoints.HandsetLandscape];

          this.biggerMap = result.breakpoints[Breakpoints.Large]
            || result.breakpoints[Breakpoints.XLarge];
        }
      });
  }

  getSurnames() {
    this.personsService.getItems()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (persons) => {
          const allSurnames = UtilsService
            .groupAndSortBy(persons, 'lastName');
          this.surnameList = allSurnames.splice(0, 20);
          this.surnameList.forEach(item => {
            item.slug = UtilsService.slugify(item.title);
          });
          this.cdr.detectChanges();
        }
      });
  }

  getQuotes() {
    this.quotesService
      .getItemsQuery('important', '==', true)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          response = this.shuffle.transform(response);
          this.dailyQuotes = response.splice(0, 50);
          this.randomizeQuote();
        },
      });
  }

  randomizeQuote() {
    const randomIndex = Math.floor(Math.random() * this.dailyQuotes.length);
    this.randomQuote = this.dailyQuotes[randomIndex];
  }

  randomizeEvent(changeEvent = false) {
    if (changeEvent) {
      const id = this.randomEvent.id;
      const otherEvents = this.dailyEvents.filter(e => e.id !== id);
      const randomIndex = Math.floor(Math.random() * (this.dailyEvents.length - 1));
      this.randomEvent = otherEvents[randomIndex];
    } else {
      const randomIndex = Math.floor(Math.random() * this.dailyEvents.length);
      this.randomEvent = this.dailyEvents[randomIndex];
    }

    if (this.randomEvent) {
      this.randomEvent.html = this.styleFirstWord(this.randomEvent);
    }

    this.cdr.detectChanges();
  }

  randomizeBirthday() {
    const randomIndex = Math.floor(Math.random() * this.cards.length);
    this.randomBirthday = this.cards[randomIndex];
  }

  getCategories() {
    this.categories$ = this.categoriesService.getItemsSorted('order');
  }

  getTimelineEvents() {
    const chronologies$ = this.chronologyService.getItemsSorted('age');
    const chronologyDots$ = chronologies$.pipe(
      map(arr => arr.filter(e => e.typeId === ChronologyType.state))
    );

    chronologyDots$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: Chronology[]) => {
          this.timelineEvents = [...response];
        }
      });
  }

  getPersonCards() {
    this.cards$ = this.getBirthdayPersons();

    this.cards$.subscribe({
      next: (cards: Person[]) => {
        this.cards = cards;
        this.randomizeBirthday();
      }
    });
  }

  getTiles() {
    this.tiles$ = this.personsService
      .getItemsQuery('frontPage', '==', true)
      .pipe(
        first(),
        map(arr => arr.sort((a, b) =>
          (a.birthYear as number) - (b.birthYear as number)))
      );
  }

  getDailyHolidays() {
    let day = UtilsService.getTodayDay();
    let month = UtilsService.getTodayMonth();
    this.saintsService.getItemsSorted('saintDate')
      .pipe(
        map((holidays) => {
          const fixHolidays = UtilsService.setMovableHolidays(holidays);
          const filteredItems = fixHolidays
            .map(h => {
              (h as any).isHoliday = true;
              return h;
            })
            .filter(h => {
              return String(h.saintMonth) === String(month)
                && String(h.saintDay) === String(day);
            });
          return filteredItems;
        }),

        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (response) => {
          this.dailyEvents = [...response, ...this.dailyEvents];
          this.randomizeEvent();
          this.cdr.detectChanges();
        }
      });
  }

  getFunFacts() {
    const args = [];

    args.push('day');
    args.push(0);

    args.push('month');
    args.push(0);

    this.historicalEventsService.getItemsQueries(...args)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (response) => {
          response = this.shuffle.transform(response);
          this.funfactBoxes = response
            .map((item: HistoricalEvent) => {
              return {
                style: '',
                id: item.id || Math.floor(Math.random() * 100_000),
                imagePath: item.imagePath || '',
                title: item.title || '',
                description: item.description || '',
                link: item.slug || '',
                icon: null,
                background: '',
              };
            });
        }
      });
  }

  getDailyEvents() {
    const args = [];

    let day = UtilsService.getTodayDay();
    args.push('day');
    args.push(+day);

    let month = UtilsService.getTodayMonth();
    args.push('month');
    args.push(+month);


    this.historicalEventsService.getItemsQueries(...args)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          response.sort((a: HistoricalEvent, b: HistoricalEvent) => {
            const bYear = a.description?.substring(0, 4);
            const aYear = b.description?.substring(0, 4);
            return bYear!.localeCompare(aYear!);
          });
          this.dailyEvents = [...this.dailyEvents, ...response];
          this.randomizeEvent();
          this.cdr.detectChanges();
        }
      });
  }

  checkDailyQuiz() {
    const today = UtilsService.getTodayFormatted();
    const storageName = `quiz-${today}`;
    const storageQuiz = localStorage.getItem(storageName);

    if (storageQuiz) {
      this.gamePoints = JSON.parse(storageQuiz);

      this.totalScore = this.gamePoints.reduce((a, b) => a + b);
    }
  }

  mergeObservables(o1$: any, o2$: any): Observable<Person[]> {
    return zip(o1$, o2$).pipe(
      map((res: any) => [].concat(...res)),
      map((res: any) => this.removeDuplicatesByProperty(res, 'id')),
    );
  }

  sortByProperty(arr: any[], property: string) {
    return arr.sort((a: any, b: any) => {

      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  removeDuplicatesByProperty(arr: any[], property: string) {
    return arr.filter((obj, index, self) => {
      return index === self.findIndex((item) => item[property] === obj[property]);
    });
  }

  getBirthdayPersons() {
    const birthdayDay = UtilsService.getTodayDay();
    const monthOrder = UtilsService.getTodayMonth();
    const monthTitle = UtilsService.getMonthTitleFromOrder(monthOrder).toLowerCase();
    this.currentDate = `${birthdayDay}. ${monthTitle}`;

    return this.personsService
      .getItemsQueries('birthDay', +birthdayDay, 'birthMonth', +monthOrder)
      .pipe(map((array) => array.slice()
        .sort((a, b) => Number(a.birthYear) - Number(b.birthYear))),
      );
  }

  onDailySaint() {
    this.router.navigateByUrl('srpsko-nasledje/slave-i-praznici');
  }

  onCategories = () => {
    this.router.navigateByUrl('/kategorije');
  };

  onCategory(path: string) {
    this.router.navigateByUrl(`/kategorija/${path}`);
  };

  onQuotesPage = () => {
    this.router.navigateByUrl('/citati');
  };

  onQuiz = () => {
    this.router.navigateByUrl(`kviz`);
  };

  onInstragram = () => {
    this.router.navigateByUrl(`instagram`);
  };

  onSurnames = () => {
    this.router.navigateByUrl(`prezime`);
  };

  onBirthdayHeadline = () => {
    const path = UtilsService.getTodaySlug();
    this.router.navigateByUrl(`kalendar/${path}`);
  };

  onDailyEvent() {
    const path = UtilsService.getTodaySlug();
    this.router.navigateByUrl(`kalendar/${path}`);
  }

  onHeritage = () => {
    this.router.navigateByUrl(`srpsko-nasledje`);
  };

  onHeritageBox(path: string) {
    this.router.navigateByUrl(`srpsko-nasledje/${path}`);
  }

  onChronology = () => {
    this.router.navigateByUrl(`vremeplov`);
  };

  onChronologyBox(path: string) {
    this.router.navigateByUrl(`vremeplov/${path}`);
  }

  onFunFacts = () => {
    this.router.navigateByUrl(`zanimljivosti`);
  };

  onFunFactBox(item: MiniBox) {
    const items = [...this.funfactBoxes];
    const data = { item, items };
    const sheet = BasicSheetComponent<any>;

    const panelClass = 'basic-sheet-overlay';
    const scrollStrategy = new NoopScrollStrategy();
    const disableClose = false;

    const href = this.router.url;
    this.location.go(href + '#');

    const sheetRef = this.matBottomSheet.open(sheet, {
      data, panelClass, scrollStrategy, disableClose
    });

    sheetRef.afterDismissed().subscribe((onX: boolean) => {
      if (onX) {
        this.location.back();
      } else {
        if (window.location.href.endsWith('#')) {
          this.location.back();
        };
      }
    });
  }

  onTag = () => {
    this.router.navigateByUrl(`tagovi`);
  };

  onTagBox = (path: string) => {
    const link = `tag/${path}/licnosti`;
    this.router.navigateByUrl(link);
  };

  onInstagramBox = (path: string) => {
    const link = `instagram#${path}`;
    this.router.navigateByUrl(link);
  };

  onSurname = (title: string) => {
    const link = `prezime/${title}`;
    this.router.navigateByUrl(link);
  };

  onTimeline = () => {
    this.router.navigateByUrl(`vremeplov/razvoj-srpske-drzave`);
  };

  onDashCard(slotIndex: number, card: any) {
    this.slots[slotIndex] = card;
    const dashIndexes = this.slots.map(d => d.id);
    localStorage.setItem('slots', JSON.stringify(dashIndexes));
  }

  styleFirstWord(randomEvent: any) {
    const text = randomEvent.description;
    const firstWordEnd = text.indexOf(' ');
    if (randomEvent.isHoliday) {
      return text;
    }

    return `<span class="first-word">${text.substring(0, firstWordEnd)}</span>${text.substring(firstWordEnd)}`;
  }


  extractDate(birthDeath: string): string {
    return birthDeath.split('.')[0] + '.';
  }

  extractMonth(birthDeath: string): string {
    return birthDeath.split('.')[1] + '.';
  }

  extractRest(birthDeath: string): string {
    const parts = birthDeath.split('.');
    parts.shift();
    parts.shift();
    return parts.join('.');
  }

  onAuthor(slug: string | null) {
    if (slug) {
      this.router.navigateByUrl(`biografija/${slug}`);
    }
  }
}

