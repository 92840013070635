<div class="welcome-page-wrapper">

  <!-- MAIN LOGO -->
  <div class="dashboard"
    #logo>
    <div class="dashboard-left">
      <ng-container *ngTemplateOutlet="dashboardSlot; context: {slotIndex: 0}" />
      <ng-container *ngTemplateOutlet="dashboardSlot; context: {slotIndex: 1}" />
      <ng-container *ngTemplateOutlet="dashboardSlot; context: {slotIndex: 2}" />
    </div>
    <div class="dashboard-center">
      <ng-container *ngTemplateOutlet="logoTemplate" />
      <app-serbian-map [biggerMap]="biggerMap" />
    </div>
    <div class="dashboard-right">
      <ng-container *ngTemplateOutlet="dashboardSlot; context: {slotIndex: 3}" />
      <ng-container *ngTemplateOutlet="dashboardSlot; context: {slotIndex: 4}" />
      <ng-container *ngTemplateOutlet="dashboardSlot; context: {slotIndex: 5}" />
    </div>
  </div>

  <!-- DAILY EVENTS -->
  @if (dailyEvents.length && smallScreen) {
  <app-horizontal-scroll #daily
    [mainTitle]="eventsTitle"
    [subtitle]="eventsSubtitle"
    [method]="onBirthdayHeadline">
    @defer (on viewport(daily)) {
    @for (item of dailyEvents; track item) {
    <app-mini-daily [fixed]="false"
      [item]="item" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>
  }


  <!-- BIRTHDAY CARDS -->
  @let cards = cards$ | async;
  @let birthdayTitle = "Календар";
  @let birthdaySubtitle = "Рођени на дан - " + currentDate;

  <app-horizontal-scroll #birthdays
    [mainTitle]="birthdayTitle"
    [subtitle]="birthdaySubtitle"
    [method]="onBirthdayHeadline">
    @defer (on viewport(birthdays)) {
    @for (card of cards; track card.id) {
    <app-person-mini-card [card]="card" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- HERITAGE -->
  <app-horizontal-scroll #heritage
    mainTitle="Знаменитости"
    subtitle="Културно-историјска баштина"
    [method]="onHeritage">
    @defer (on viewport(heritage)) {
    @for (box of heritageBoxes; track box; let i = $index) {
    <app-mini-box (clickEmitter)="onHeritageBox($event)"
      [box]="box | miniBoxParser: i : imagePrefix" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- QUOTES -->
  <app-horizontal-scroll #quotes
    mainTitle="Издвојени цитати"
    subtitle="Мисли српских аутора"
    [method]="onQuotesPage">
    @defer (on viewport(quotes)) {
    @for (quote of dailyQuotes; track quote; let i = $index) {
    <app-mini-text [index]="i"
      (click)="onAuthor(quote.authorSlug)"
      [item]="quote" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- CHRONOLOGY -->
  <app-horizontal-scroll #chronology
    mainTitle="Хронологија"
    subtitle="Кључне године српске историје"
    [method]="onChronology">
    @defer (on viewport(chronology)) {
    @for (box of chronologyBoxes; track box.path; let i = $index) {
    <app-mini-box (clickEmitter)="onChronologyBox($event)"
      [box]="box | miniBoxParser: i : imagePrefix" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- TIMELINE EVENTS -->
  <app-horizontal-scroll #timeline
    mainTitle="Времеплов историје"
    subtitle="Развој српске државе"
    [method]="onTimeline">
    @defer (on viewport(timeline)) {
    @for (event of timelineEvents; track event; let i = $index) {
    <app-mini-text [index]="i"
      [item]="event" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- FUN FACTS -->
  <app-horizontal-scroll #funfacts
    mainTitle="Да ли сте знали?"
    subtitle="Занимљивости о Србији"
    [method]="onFunFacts">
    @defer (on viewport(funfacts)) {
    @for (box of funfactBoxes; track box; let i = $index) {
    <app-mini-box (clickEmitter)="onFunFactBox(box)"
      [textOver]="true"
      [box]="box" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- CATEGORIES -->
  @let categoryList = categories$ | async;
  @let categoryTitle = "Претрага личности";
  @let categorySubtitle = "Изабери категорију";

  <app-horizontal-scroll #categories
    [mainTitle]="categoryTitle"
    [subtitle]="categorySubtitle"
    [method]="onCategories">
    @defer (on viewport(categories)) {
    @for (box of categoryList; track box; let i = $index) {
    <app-mini-box [invertColor]="true"
      (clickEmitter)="onCategory($event)"
      [box]="box | miniBoxParser: i : imagePrefix" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- THE GREATEST -->
  @let tileList = tiles$ | async;
  @let tileTitle = "Српски великани";
  @let tileSubtitle = "Славне личности Србије";

  <app-horizontal-scroll #greatest
    [mainTitle]="tileTitle"
    [subtitle]="tileSubtitle">
    <div class="mini-card-wrapper">
      @defer (on viewport(greatest)) {
      @for (card of tileList; track card.id) {
      <app-person-mini-card [card]="card" />
      }
      } @placeholder (minimum 500ms) {
      <br />
      }
    </div>
  </app-horizontal-scroll>

  <!-- TAGS -->
  <app-horizontal-scroll #tagList
    mainTitle="Издвојени тагови"
    subtitle="Истражите области интересовања"
    [method]="onTag">
    @defer (on viewport(tagList)) {
    @for (box of tagBoxes; track box; let i = $index) {
    <app-mini-box (clickEmitter)="onTagBox($event)"
      [box]="box | miniBoxParser: 2 : imagePrefix" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- INSTAGRAM -->
  <app-horizontal-scroll #instagramEmbeds
    mainTitle="Инстаграм објаве"
    subtitle="Постани део заједнице"
    [method]="onInstragram">
    @defer (on viewport(instagramEmbeds)) {
    @for (box of instragramTypes; track box.id; let i = $index) {
    <app-mini-box (clickEmitter)="onInstagramBox($event)"
      [box]="box | miniBoxParser: i : imagePrefix" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- SURNAMES -->
  <app-horizontal-scroll #surnames
    mainTitle="СРПСКА ПРЕЗИМЕНА"
    subtitle="Нађи своје презимењаке"
    [method]="onSurnames">
    @defer (on viewport(surnames)) {
    @for (box of surnameList; track box.title; let i = $index) {
    <app-mini-box (clickEmitter)="onSurname($event)"
      [box]="box | miniBoxParser: i : imagePrefix" />
    }
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- QUIZ -->
  <app-horizontal-scroll #quiz
    mainTitle="Дневни квиз"
    subtitle="Тестирај своје знање"
    [method]="onQuiz">
    @defer (on viewport(quiz)) {
    <app-daily-quiz [shorten]="true" />
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>

  <!-- STAT COUNTER -->
  @if(smallScreen) {
  <app-horizontal-scroll #statCounter
    mainTitle="Бројач података"
    subtitle="Унети ентитети у базу података">
    @defer (on viewport(statCounter)) {
    <app-stats-counter />
    } @placeholder (minimum 500ms) {
    <br />
    }
  </app-horizontal-scroll>
  }

  <br />
</div>

<ng-template #logoTemplate>
  <div class="logo-wrapper">
    @if (themeService.isLightMode() && themeService.isCyrillicMode()) {
    <img loading="lazy"
      src="assets/images/gallery-logo-text-outline.png"
      alt="Logo Galerija" />
    }

    @if (themeService.isDarkMode() && themeService.isCyrillicMode()) {
    <img loading="lazy"
      src="assets/images/gallery-logo-text-outline.png"
      alt="Logo Galerija" />
    }

    @if (themeService.isLightMode() && themeService.isLatinMode()) {
    <img loading="lazy"
      src="assets/images/gallery-logo-text-latin.png"
      alt="Logo Galerija" />
    }

    @if (themeService.isDarkMode() && themeService.isLatinMode()) {
    <img loading="lazy"
      src="assets/images/gallery-logo-text-latin.png"
      alt="Logo Galerija" />
    }
  </div>
</ng-template>

<ng-template #dashboardSlot
  let-slotIndex="slotIndex">

  @defer (on idle) {
  @if (slots.length) {
  <div class="slot"
    [style.animationDuration]="slotIndex + 's'">
    <h5 class="slot-header">
      <span class="slot-title gagalin">
        {{(slots[slotIndex]?.title || slotIndex) | translit}}
      </span>
      <mat-icon [matMenuTriggerFor]="slotMenu">more_vert</mat-icon>
      <mat-menu panelClass="slot-menu"
        xPosition="before"
        yPosition="below"
        #slotMenu="matMenu">
        <div class="dash-cards">
          @for (card of dashCards; track card) {
          <p class="dash-card"
            (click)="onDashCard(slotIndex, card)">
            {{card.title | translit}}
          </p>
          }
        </div>
      </mat-menu>
    </h5>
    @switch(slots[slotIndex].id) {
    @case (1) {
    <ng-container *ngTemplateOutlet="birthdaySlot" />
    }
    @case (2) {
    <ng-container *ngTemplateOutlet="quoteSlot" />
    }
    @case (3) {
    <ng-container *ngTemplateOutlet="eventSlot" />
    }
    @case (4) {
    <ng-container *ngTemplateOutlet="sightsSLot" />
    }
    @case (5) {
    <ng-container *ngTemplateOutlet="statSlot" />
    }
    @case (6) {
    <ng-container *ngTemplateOutlet="chronologySlot" />
    }
    }
  </div>
  }
  }
</ng-template>

<ng-template #birthdaySlot>
  @if (randomBirthday) {
  <div class="birthday-slot">
    <img class="person-image"
      [src]="randomBirthday.imagePath"
      [alt]="randomBirthday.commonName">
    <section>
      <h4>{{randomBirthday.commonName | translit}}</h4>
      <p>{{randomBirthday.fullTitle | translit}}</p>
      <p>{{randomBirthday.city | translit}}</p>
      <p>
        <span class="date-day">{{extractDate(randomBirthday.birthDeath!)}}</span>
        <span class="date-month">{{extractMonth(randomBirthday.birthDeath!)}}</span>
        <span class="date-rest">{{extractRest(randomBirthday.birthDeath!)}}.</span>
      </p>
      @if (randomBirthday.birthYear && !randomBirthday.age) {
      <footer>
        {{currentYear - randomBirthday.birthYear}}
      </footer>
      <img class="birthday-balloons"
        loading="lazy"
        src="assets/images/balloons.png"
        alt="birthday" />
      }
      <mat-icon (click)="randomizeBirthday()">refresh</mat-icon>
    </section>
  </div>
  }
</ng-template>

<ng-template #statSlot>
  <div class="stat-counter-slot">
    <img src="assets/images/stats-up.png"
      alt="Stats">
    <app-stats-counter [borderless]="true" />
  </div>
</ng-template>

<ng-template #eventSlot>
  @if (randomEvent) {
  <div class="event-slot">
    <img [src]="randomEvent.imagePath"
      [alt]="randomEvent.title">
    <section>
      <h4>{{randomEvent.title | translit}}</h4>
      <p class="event-multiline"
        [class.no-title]="randomEvent.isHoliday"
        [innerHTML]="randomEvent.html | translit"></p>
      @if (dailyEvents.length > 1) {
      <mat-icon (click)="randomizeEvent(true)">refresh</mat-icon>
      }
    </section>
  </div>
  } @else {
  <p class="no-event-message">
    {{'Нема историјских догађаја или празника за данашњи дан.' | translit}}
  </p>
  <mat-icon class="no-event-icon">sentiment_dissatisfied</mat-icon>
  }
</ng-template>

<ng-template #sightsSLot>
  <div class="heritage-slot">
    @for (box of heritageBoxes; track box.path) {
    <div class="heritage-item"
      (click)="onHeritageBox(box.path)">
      <mat-icon>{{box.icon}}</mat-icon>
      <h4>{{box.title | translit}}</h4>
    </div>
    }
  </div>
</ng-template>

<ng-template #quoteSlot>
  @if (randomQuote) {
  <div class="quote-slot">
    <section>{{randomQuote.authorName | translit}}:</section>
    <p>“{{randomQuote.text | trimWord | translit}}”</p>
    <mat-icon class="reload"
      (click)="randomizeQuote()">refresh</mat-icon>
    <mat-icon class="quote">format_quote</mat-icon>
  </div>
  }
</ng-template>

<ng-template #chronologySlot>
  <div class="heritage-slot">
    @for (box of chronologyBoxes; track box.path) {
    <div class="heritage-item chronology"
      (click)="onChronologyBox(box.path)">
      <mat-icon>{{box.icon}}</mat-icon>
      <h4>{{box.title | translit}}</h4>
    </div>
    }
  </div>
</ng-template>