

import { distinctUntilChanged } from 'rxjs';

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { UtilsService } from '@app/services/utils.service';
import { AuthService } from '@services/auth.service';
import { GlossaryService } from '@services/glossary.service';
import { ResponsiveService } from '@services/responsive.service';
import { ThemeService } from '@services/theme.service';

interface SidebarItem {
  icon: string,
  title: string,
  method: Function,
}

@Component({
  selector: 'app-drawer-sidebar',
  imports: [MatToolbarModule, MatIconModule],
  templateUrl: './drawer-sidebar.component.html',
  styleUrls: ['./drawer-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerSidebarComponent {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private responsiveService = inject(ResponsiveService);
  private cdr = inject(ChangeDetectorRef);
  private translitPipe = new TranslitPipe();

  public sidebarItems: SidebarItem[] = [];
  public Glossary = GlossaryService.Glossary;
  public themeService = inject(ThemeService);
  public authService = inject(AuthService);

  ngOnInit() {
    this.initSidebarItems();
    this.setAdminListener();
  }

  initSidebarItems() {
    const persons = this.translitPipe.transform('Личности');
    const sights = this.translitPipe.transform('Знаменитости');
    const timeline = this.translitPipe.transform('Времеплов');
    const quotes = this.translitPipe.transform('Цитати');
    const calendar = this.translitPipe.transform('Календар');
    const quiz = this.translitPipe.transform('Квиз');
    const instagram = this.translitPipe.transform('Инстаграм');
    const tags = this.translitPipe.transform('Тагови');

    this.sidebarItems = [
      {
        icon: 'workspaces', title: persons,
        method: () => this.onCategories()
      },
      {
        icon: 'account_balance', title: sights,
        method: () => this.onHeritage()
      },
      {
        icon: 'history', title: timeline,
        method: () => this.onChronology()
      },
      {
        icon: 'format_quote', title: quotes,
        method: () => this.onQuotes()
      },
      {
        icon: 'cake', title: calendar,
        method: () => this.onBirthdays()
      },
      {
        icon: 'quiz', title: quiz,
        method: () => this.onQuiz()
      },
      {
        icon: 'share', title: instagram,
        method: () => this.onInstagram()
      },
      {
        icon: 'tags', title: tags,
        method: () => this.onTags()
      },
    ];
  }

  setAdminListener() {
    this.authService.loginAdmin$
      ?.pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (isAdmin) => {

          if (isAdmin) {

            const adminTitle = this.translitPipe.transform('Админ');
            const adminItem = {
              icon: 'admin_panel_settings',
              title: adminTitle,
              method: () => this.onAdmin()
            };

            this.sidebarItems.push(adminItem);
            this.cdr.detectChanges();

          } else {
            this.sidebarItems = this.sidebarItems
              .filter(i => i.icon !== 'admin_panel_settings');
            this.cdr.detectChanges();
          }

        }
      });
  }

  closeDrawer() {
    if (!this.responsiveService.over1200) {
      this.responsiveService.closeDrawer();
    }

    this.cdr.detectChanges();
  }

  onHome() {
    this.router.navigateByUrl('');
    this.closeDrawer();
  }

  onContact() {
    this.router.navigateByUrl('kontakt');
    this.closeDrawer();
  }

  onAdmin() {
    this.router.navigateByUrl('admin/panel');
    this.closeDrawer();
  }

  onAccount() {
    this.router.navigateByUrl('nalog');
    this.closeDrawer();
  }

  onQuotes() {
    this.router.navigateByUrl('citati');
    this.closeDrawer();
  }

  onBirthdays() {
    const path = UtilsService.getTodaySlug();
    this.router.navigateByUrl(`kalendar/${path}`);
    this.closeDrawer();
  }

  onCategories() {
    this.router.navigateByUrl(`kategorije`);
    this.closeDrawer();
  }

  onTags() {
    this.router.navigateByUrl(`tagovi`);
    this.closeDrawer();
  }

  onInstagram() {
    this.router.navigateByUrl(`instagram`);
    this.closeDrawer();
  }

  onHeritage() {
    this.router.navigateByUrl(`srpsko-nasledje`);
    this.closeDrawer();
  }

  onChronology() {
    this.router.navigateByUrl(`vremeplov`);
    this.closeDrawer();
  }

  onQuiz() {
    this.router.navigateByUrl(`kviz`);
    this.closeDrawer();
  }

}
