<mat-toolbar class="bottom-toolbar">

  <!-- SEARCH -->
  <button mat-button
    class="bottom-button"
    (click)="onCalendar()">
    <mat-icon> cake </mat-icon>
  </button>
  <button mat-button
    class="bottom-button"
    (click)="onQuotes()">
    <mat-icon> format_quote </mat-icon>
  </button>
  <button mat-button
    class="bottom-button search-button"
    (click)="onHome()">
    <mat-icon class="middle-icon"> home </mat-icon>
  </button>
  <button mat-button
    class="bottom-button"
    (click)="onHeritage()">
    <mat-icon>account_balance</mat-icon>
  </button>
  <button mat-button
    class="bottom-button"
    (click)="onChronology()">
    <mat-icon> history </mat-icon>
  </button>

</mat-toolbar>