import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimWord'
})
export class TrimWordPipe implements PipeTransform {
  transform(value: any): string {
    return value ? value.trim() : value;
  }
}
