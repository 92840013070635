import { lastValueFrom } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { HistoricalEventsService } from '@app/services/historical-events.service';
import { ManifestationService } from '@app/services/manifestations.service';
import { NatureService } from '@app/services/nature.service';
import { ChronologyService } from '@services/chronology.service';
import { ChurchService } from '@services/church.service';
import { MemorialService } from '@services/memorial.service';
import { PersonsService } from '@services/persons.service';
import { QuotesService } from '@services/quotes.service';
import { SaintsService } from '@services/saints.service';
import { SymbolsService } from '@services/symbols.service';

@Component({
    selector: 'app-stats-counter',
    imports: [CommonModule, TranslitPipe],
    templateUrl: './stats-counter.component.html',
    styleUrls: ['./stats-counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsCounterComponent {
  stickToSide = input<boolean>(false);
  borderless = input<boolean>(false);

  private cdr = inject(ChangeDetectorRef);
  private personsService = inject(PersonsService);
  private memorialService = inject(MemorialService);
  private natureService = inject(NatureService);
  private manifestationService = inject(ManifestationService);
  private historicalEventsService = inject(HistoricalEventsService);
  private quotesService = inject(QuotesService);
  private chronologyService = inject(ChronologyService);
  private churchService = inject(ChurchService);
  private saintsService = inject(SaintsService);
  private symbolsService = inject(SymbolsService);
  private router = inject(Router);

  public personCounter = 0;
  public quoteCounter = 0;
  public chronologyCounter = 0;
  public heritageCounter = 0;
  public counterData = [
    { title: 'Личности', counter: 0 },
    { title: 'Цитати', counter: 0 },
    { title: 'Наслеђе', counter: 0 },
    { title: 'Хроника', counter: 0 },
  ];

  ngOnInit(): void {
    this.getCounters();
  }

  async getCounters() {
    const personData = await lastValueFrom(this.personsService.countItems());
    this.personCounter += personData.data().count;

    const quoteData = await lastValueFrom(this.quotesService.countItems());
    this.quoteCounter += quoteData.data().count;

    const memorialData = await lastValueFrom(this.memorialService.countItems());
    this.heritageCounter += memorialData.data().count;

    const natureData = await lastValueFrom(this.natureService.countItems());
    this.heritageCounter += natureData.data().count;

    const manifestationData = await lastValueFrom(this.manifestationService.countItems());
    this.heritageCounter += manifestationData.data().count;

    const churchData = await lastValueFrom(this.churchService.countItems());
    this.heritageCounter += churchData.data().count;

    const saintData = await lastValueFrom(this.saintsService.countItems());
    this.heritageCounter += saintData.data().count;

    const symbolData = await lastValueFrom(this.symbolsService.countItems());
    this.heritageCounter += symbolData.data().count;

    const chronologyData = await lastValueFrom(this.chronologyService.countItems());
    this.chronologyCounter += chronologyData.data().count;

    const historicalEvents = await lastValueFrom(this.historicalEventsService.countItems());
    this.chronologyCounter += historicalEvents.data().count;

    this.cdr.detectChanges();
  }

  onPersonCounter() {
    this.router.navigateByUrl('kategorije');
  }

  onQuotesCounter() {
    this.router.navigateByUrl('citati');
  }

  onHeritageCounter() {
    this.router.navigateByUrl('srpsko-nasledje');
  }

  onChronologyCounter() {
    this.router.navigateByUrl('vremeplov');
  }
}
