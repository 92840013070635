


import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, ViewChild
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { AuthService } from '@services/auth.service';
import { Person, PersonsService } from '@services/persons.service';

import { DrawerContentComponent } from './components/drawer-content/drawer-content.component';
import { DrawerSidebarComponent } from './components/drawer-sidebar/drawer-sidebar.component';
import { TranslitPipe } from './pipes/translit.pipe';
import { PwaUpdateService } from './services/pwa-update.service';
import { ResponsiveService } from './services/responsive.service';
import { TagsService } from './services/tags.service';
import { ThemeService } from './services/theme.service';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  imports: [
    MatSidenavModule,
    DrawerContentComponent,
    TranslitPipe,
    DrawerSidebarComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  @ViewChild('drawer') drawer!: MatDrawer;

  private intervalId: any;
  private authService = inject(AuthService);
  private responsiveService = inject(ResponsiveService);
  private pwaUpdateService = inject(PwaUpdateService);
  private tagsService = inject(TagsService);
  private personsService = inject(PersonsService);
  protected utilsService = inject(UtilsService); // init injector in service

  public themeService = inject(ThemeService);
  public changeDetectorRef = inject(ChangeDetectorRef);
  public userProfileChecked = false;
  public response!: Person[];
  public translitTriggered = false;

  public over1200 = false;

  public currentCity = '';

  ngOnInit() {
    this.initAuth();
    this.initPWA();
    this.initListeners();
    this.initPersonList();
    this.listenTranslit();
    this.runCityMixer();
  }

  ngAfterViewInit() {
    if (!this.responsiveService.queryUnder1200) {
      return;
    }
    this.over1200 = this.responsiveService.over1200;
    this.changeDetectorRef.detectChanges();

    this.responsiveService.queryUnder1200.addListener(() => {
      this.over1200 = this.responsiveService.over1200;
      this.changeDetectorRef.detectChanges();
    });
  }

  runCityMixer(): void {
    const cityList = this.tagsService.cityList;

    UtilsService.shuffleListOrder(cityList);
    this.currentCity = cityList[0].name;
    let currentIndex = 3;

    this.intervalId = setInterval(() => {
      this.currentCity = cityList[currentIndex].name;
      currentIndex++;

      this.changeDetectorRef.detectChanges();
    }, 75);
  }

  listenTranslit() {
    this.themeService.translitTrigger$$.subscribe({
      next: (_ => {
        this.translitTriggered = false;
        setTimeout(() => {
          this.translitTriggered = true;
          this.changeDetectorRef.detectChanges();
        }, 400);
      })
    });
  }

  initPersonList() {
    this.personsService.getItemsServer()
      .subscribe({
        next: response => {
          this.response = response;
          setTimeout(() => {
            this.changeDetectorRef.detectChanges();
            if (this.intervalId) {
              clearInterval(this.intervalId);
            }
            if (this.drawer) {
              this.responsiveService.setDrawer(this.drawer);
            }
          }, 100);
        }
      });
  }

  initPWA() {
    this.pwaUpdateService.listenPwaUpdates();
  }

  initListeners() {
    this.responsiveService.initResponsiveListener();
  }

  initAuth() {
    this.authService.initAuth();
    this.authService.user$.subscribe((_) => {
      this.userProfileChecked = true;
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
        if (this.drawer) {
          this.responsiveService.setDrawer(this.drawer);
        }
      }, 100);
    });
  }

}
