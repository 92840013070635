

import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, inject
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RoutesRecognized } from '@angular/router';
import { SearchDialogComponent } from '@app/dialogs/search-dialog/search-dialog.component';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { AuthService } from '@services/auth.service';
import { GlossaryService } from '@services/glossary.service';
import { ResponsiveService } from '@services/responsive.service';
import { ThemeService } from '@services/theme.service';

@Component({
  selector: 'app-header',
  imports: [MatDialogModule, MatToolbarModule, MatIconModule, TranslitPipe],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  private router = inject(Router);
  private responsiveService = inject(ResponsiveService);
  private location = inject(Location);
  private matDialog = inject(MatDialog);

  public Glossary = GlossaryService.Glossary;
  public changeDetectorRef = inject(ChangeDetectorRef);
  public themeService = inject(ThemeService);
  public authService = inject(AuthService);
  public showBackButton = false;
  public over1200 = false;

  ngOnInit() {
    this.listenRoutes();
  }

  ngAfterViewInit() {
    if (!this.responsiveService.queryUnder1200) {
      return;
    }

    this.over1200 = this.responsiveService.over1200;
    this.changeDetectorRef.detectChanges();

    this.responsiveService.queryUnder1200.addListener(() => {
      this.over1200 = this.responsiveService.over1200;
      this.changeDetectorRef.detectChanges();
    });
  }

  @HostListener('window:keydown.control.space')
  onSearch() {
    const isAnyDialogOpen = this.matDialog?.openDialogs?.length > 0;

    if (isAnyDialogOpen) {
      return;
    }

    this.matDialog.open(SearchDialogComponent, {
      panelClass: 'search-dialog-container',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  onInstagram() {
    const profile = 'https://www.instagram.com/galerijasrbije.rs/';
    window.open(profile, '_blank');
  }

  listenRoutes() {
    this.showBackButton = this.router.url !== '/';
    this.changeDetectorRef.detectChanges();

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.showBackButton = data.url !== '/';
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onBack() {
    this.location.back();
  }

  onMenu() {
    this.responsiveService.toggleDrawer();
    this.changeDetectorRef.detectChanges();
  }

  onContact() {
    this.router.navigateByUrl('kontakt');
    this.changeDetectorRef.detectChanges();
  }

  onAccount() {
    this.router.navigateByUrl('nalog');
    this.changeDetectorRef.detectChanges();
  }

  onFavourites() {
    this.router.navigateByUrl(`favoriti`);
    this.changeDetectorRef.detectChanges();
  }

  onSettings() {
    this.router.navigateByUrl('nalog');
    this.changeDetectorRef.detectChanges();
  }

}
